import Logo from '../assets/logo-white.svg'
import Separator from "./Separator";

function Footer() {
    return (
        <div className="footer">
            <div className="row">
                <div className="footer__brand">
                    <img alt="logo" src={Logo}/> Eniltrex Iberica
                    <div className="footer__brand__description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </div>
                </div>
                <div className="footer__menu">
                    <div className="footer__menu__title">Eniltrex</div>
                    <div>About us</div>
                    <div>Services</div>
                </div>
                <div className="footer__menu">
                    <div className="footer__menu__title">Contact</div>
                    <div>Call us 613 911 987</div>
                    <div>Twitter</div>
                    <div>Instragram</div>
                    <div>Facebook</div>
                    <div>Email</div>
                </div>
                <div className="footer__menu">
                    <div className="footer__menu__title">Legal</div>
                    <div>Cookies</div>
                    <div>Privacy policy</div>
                </div>
            </div>
            <Separator/>
            <div className="footer__rights__claim">
                Eniltrex Iberica 2022
            </div>
        </div>
    );
}

export default Footer;
