import '../scss/pages/Home.scss';
import TextImageBlock from "../components/TextImageBlock";
import Separator from "../components/Separator";

function AboutUs() {
    return (
        <div className="home">
            <div className="container">
                <h1>About us</h1>
            </div>
            <TextImageBlock/>
            <Separator/>
        </div>
    )
}
export default AboutUs;