import '../scss/pages/Home.scss';
import TextImageBlock from "../components/TextImageBlock";
import Separator from "../components/Separator";

function Services() {
    return (
        <div className="home">
            <div className="container">
                <h1>Services</h1>
            </div>
            <TextImageBlock/>
            <Separator/>
        </div>
    )
}
export default Services;