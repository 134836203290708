import Menu from "./components/Menu";
import Footer from "./components/Footer";
import './scss/main.scss';
import 'bootstrap';
import Home from "./pages/Home";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Services from "./pages/Services";

function App() {
    return (
        <div className="eniltex">
            <BrowserRouter>
                <Menu/>
                <div className="content">

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/services" element={<Services />} />
                        </Routes>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
