import '../scss/components/welcome.scss';

function Welcome() {
    return (
        <div className="welcome">
            <div className="welcome-content">
                <div>Hi Revolut, We love you!</div>
                 consectetur adipiscing elit, sed do eiusmod tempor incididunt
            </div>
        </div>
    )
}
export default Welcome;