import TetrisBar from "./TetrisBar";
import '../scss/components/games.scss';
import mario from "../assets/mario.jpg";
import unity from "../assets/unity.jpg";
import minesweeper from "../assets/minesweeper.jpg";
import Separator from "./Separator";

function Games() {
    return (
        <div className="games">
            <TetrisBar/>
            <Separator/>
            <div className="games__title">
                We create fun!
            </div>
            <Separator/>
            <div className="container">
                <div className="row">
                    <div className="games__game">
                        <img src={mario} alt="Mario"/>
                    </div>
                    <div className="games__game">
                        <img src={unity} alt="Unity"/>
                    </div>
                    <div className="games__game">
                        <img src={minesweeper} alt="Mine Sweeper"/>
                    </div>
                </div>
            </div>
            <Separator/>
        </div>
    )
}
export default Games;