import '../scss/pages/Home.scss';
import Welcome from "../components/Welcome";
import TextImageBlock from "../components/TextImageBlock";
import Games from "../components/Games";
import Separator from "../components/Separator";
import TextBlock from "../components/TextBlock";

function Home() {
    return (
        <div className="home">
            <Welcome/>
            <Separator/>
            <TextImageBlock/>
            <Separator/>
            <Games/>
            <Separator/>
            <TextBlock/>
            <Separator/>
        </div>
    )
}
export default Home;